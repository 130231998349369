import {Exclude, Expose, Type} from 'class-transformer';
import {BaseEntity} from '../../_helpers';
import {IncidentEntity} from '../../incident/entity';
import {ActivityEntity, DocumentEntity, NoteEntity} from '../../core/entity';
import {ThreadStatusEnum} from '../../core/enum';
import {MaintenanceCompany} from '../../core/entity/maintenance.company';
import {Confirmation} from './confirmation.entity';
import {ActivityType} from '../../core/enum/activity-type.enum';
import {ThreadAuditLogEntity} from './thread.auditLog.entity';
import {DueDate} from '../../core/entity/due-date.entity';

@Exclude()
export class ThreadEntity extends BaseEntity {

  @Expose()
  @Type(() => IncidentEntity)
  public incidents: IncidentEntity[];

  @Expose()
  @Type(() => IncidentEntity)
  public mainIncident: IncidentEntity;

  @Expose()
  public maintenanceCompany: MaintenanceCompany;

  @Expose()
  public contractId: string;

  @Expose()
  public contractName: string;

  @Expose()
  public threadNumber: string;

  @Expose()
  public contract: string;

  @Expose()
  @Type(() => Date)
  public createdDateTime: Date;

  @Expose()
  @Type(() => DueDate)
  public dueDate: DueDate;

  @Expose()
  @Type(() => Date)
  public lastActivityDateTime: Date;

  @Expose()
  @Type(() => Date)
  public closedDateTime: Date;

  @Expose()
  public exceededDueDateDuration: string;

  @Expose()
  public reactionTimeDuration: string;

  @Expose()
  public fixTimeDuration: string;

  @Expose()
  @Type(() => DocumentEntity)
  public attachments: DocumentEntity[];

  @Expose()
  @Type(() => NoteEntity)
  public notes: NoteEntity[];

  @Expose()
  @Type(() => ActivityEntity)
  public activities: ActivityEntity[];

  @Expose()
  public threadStatus: ThreadStatusEnum;

  @Expose()
  @Type(() => Confirmation)
  public confirmation: Confirmation;

  @Expose()
  @Type(() => ThreadAuditLogEntity)
  public auditLog: ThreadAuditLogEntity[];

  get number() {
    return this.threadNumber;
  }

  get standardActivitiesSortedByDate(): ActivityEntity[] {
    if (!this.activities || this.activities.length === 0) {
      return null;
    }
    return this.activities.filter((a: ActivityEntity) => a.activityType === ActivityType.STANDARD)
      .sort((a: ActivityEntity, b: ActivityEntity) => {
        return a.activityDateTime.getTime() - b.activityDateTime.getTime();
      });
  }

  get startDate(): Date {
    const list: ActivityEntity[] = this.standardActivitiesSortedByDate;
    return (list && list.length > 0) ? list[0].activityDateTime : null;
  }

  get endDate(): Date {
    const list: ActivityEntity[] = this.standardActivitiesSortedByDate;
    return (list && list.length > 0) ? list[list.length - 1].activityDateTime : null;
  }

  get isActive() {
    return this.allowsClose;
  }

  get isFinished() {
    return !!this.closedDateTime;
  }

  get allowsAssign() {
    return this.threadStatus === ThreadStatusEnum.CREATED;
  }

  get allowsAccept() {
    return this.threadStatus === ThreadStatusEnum.ASSIGNED;
  }

  get allowsReject() {
    return this.threadStatus === ThreadStatusEnum.ASSIGNED || this.threadStatus === ThreadStatusEnum.ACCEPTED;
  }

  get allowsFinish() {
    return this.threadStatus === ThreadStatusEnum.ACCEPTED;
  }

  get allowsReopen() {
    return this.threadStatus === ThreadStatusEnum.CLOSED;
  }

  get allowsClose() {
    return this.threadStatus !== ThreadStatusEnum.CLOSED && this.threadStatus !== ThreadStatusEnum.PAUSED;
  }

  get allowsChangeSla() {
    return this.threadStatus !== ThreadStatusEnum.CLOSED;
  }

  get allowsPauseSla() {
    return this.threadStatus === ThreadStatusEnum.CREATED;
  }

  get allowsResumeSla() {
    return this.threadStatus === ThreadStatusEnum.PAUSED;
  }

  get allowsAcknowledgeDeadline() {
    return this.threadStatus !== ThreadStatusEnum.CLOSED;
  }
}
